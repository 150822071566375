

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Services';
import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateHangWinner = () => {
    const [formData, setFormData] = useState({
        name: '',
        reward: '',
        draw_date: '',
        image: null,
        location: ''
    });
    const [loading, setLoading] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const navigate = useNavigate();
    const locations = [
        'Amanah Mall Model Town',
        'ChaseUp Mall gujranwala',
         ];

  
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        setFormData((prev) => ({ ...prev, image: e.target.files[0] }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        const form = new FormData();
        Object.keys(formData).forEach((key) => {
            form.append(key, formData[key]);
        });

        try {
            const response = await axios.post(`${BASE_URL}/hang-tower-winners`, form);
            toast.success('Winner created successfully!');
            navigate('/dashboard/hang-winners');
        } catch (error) {
            console.error('Error creating winner:', error);
            if (error.response) {
                if (error.response.status === 422) {
                    const errors = error.response.data.errors;
                    Object.values(errors).forEach((messages) => {
                        messages.forEach((message) => toast.error(message));
                    });
                } else {
                    toast.error('Error creating winner: ' + error.response.data.message || 'An unexpected error occurred.');
                }
            } else {
                toast.error('Network error or no response from server.');
            }
        } finally {
            setLoading(false);
        };
    };
    
    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="container-fluid col-md-12 mt-4">
                        <div className="p-4 bg-light rounded shadow-sm">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <Loader />
                                </div>
                            ) : (
                                <div>
                                    <h1 className="mb-4 fs-4 fw-bold text-dark">Create Winner</h1>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="reward" className="form-label">Reward</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="reward"
                                                name="reward"
                                                value={formData.reward}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="draw_date" className="form-label">Draw Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="draw_date"
                                                name="draw_date"
                                                value={formData.draw_date}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="image" className="form-label">Image</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                id="image"
                                                name="image"
                                                onChange={handleFileChange}
                                                required
                                            />
                                            {formData.image && (
                                                <img
                                                    style={{ height: '120px', width: '150px', borderRadius: '10px' }}
                                                    src={URL.createObjectURL(formData.image)}
                                                    className="img-fluid mt-3"
                                                    alt="Selected"
                                                />
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="location" className="form-label">Location</label>
                                          
                                            <select
                                                className="form-control"
                                                id="location"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleChange}
                                                       required
                                                                      >
                                            <option value="">Select Location</option>
                                            {locations.map((loc, index) => (
                                            <option key={index} value={loc}>
                                              {loc}
                                            </option>
                                            ))} 
                                             </select>
                                        </div>
                                        <button type="submit" className="btn btn-primary shadow-sm">
                                            Save
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default CreateHangWinner;
