import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { BASE_URL } from "../../../Services"; 

const useMonthlyStats = () => {
  const [monthlyStats, setMonthlyStats] = useState([]);
  const [users, setUsers] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUserStats = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const [usersResponse, participantsResponse, productsResponse] = await Promise.all([
        axios.get(`${BASE_URL}/users`, { headers }),
        axios.get(`${BASE_URL}/participants`, { headers }),
        axios.get(`${BASE_URL}/products`, { headers }),
      ]);
      setUsers(usersResponse.data);
      setParticipants(participantsResponse.data);
      setProducts(productsResponse.data.products);
      
    
      const stats = calculateMonthlyStats(usersResponse.data, participantsResponse.data );
      setMonthlyStats(stats);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const calculatePaidParticipants = (participants) => {
    return participants.filter((participant) => participant.total_amount > 0)
      .length;
  };
  const calculateTotalSales = (participants) => {
    return participants.reduce(
      (sum, participant) => sum + (parseFloat(participant.total_amount) || 0),
      0
    );
  };
  const calculateMonthlyStats = (users, participants) => {
    const stats = {};

    users.forEach((user) => {
      const month = moment(user.created_at).format("YYYY-MM");
      if (!stats[month]) {
        stats[month] = {
          total_users: 0,
          total_participants: 0,
          total_quantity: 0,
          total_amount: 0,
        };
      }
      stats[month].total_users += 1;
    });

    participants.forEach((participant) => {
      const month = moment(participant.created_at).format("YYYY-MM");
      if (!stats[month]) {
        stats[month] = {
          total_users: 0,
          total_participants: 0,
          total_quantity: 0,
          total_amount: 0,
        };
      }
      stats[month].total_participants += 1 || 0;
      stats[month].total_amount += parseFloat(participant.total_amount) || 0;

      if (
        participant.processed_invoice &&
        typeof participant.processed_invoice === "object"
      ) {
        const invoice = participant.processed_invoice;
        stats[month].total_quantity += parseInt(invoice.total_quantity) || 0;
      }
    });

    const monthlyData = Object.keys(stats)
    .map((month) => ({
      month: moment(month, "YYYY-MM").format("MMMM YYYY"),
      total_users: stats[month].total_users,
      total_participants: stats[month].total_participants,
      total_quantity: stats[month].total_quantity,
      total_amount: stats[month].total_amount.toFixed(2),
    }))
    .sort((a, b) => moment(b.month, "MMMM YYYY") - moment(a.month, "MMMM YYYY"));

  return monthlyData;
};

  const currentMonth = moment().format("MMMM YYYY");
  const previousMonth = moment().subtract(1, 'months').format("MMMM YYYY");
  const currentMonthData = monthlyStats.find(
    (item) => item.month === currentMonth
  );
  const previousMonthData = monthlyStats.find((item) => item.month === previousMonth);

  useEffect(() => {
    fetchUserStats();
  }, []);
  useEffect(() => {
    fetchUserStats();
    const interval = setInterval(fetchUserStats, 60000);
    return () => clearInterval(interval);
  }, []);
  return { monthlyStats, currentMonthData,previousMonthData, users, participants, products,calculatePaidParticipants,calculateTotalSales, loading };
};

export default useMonthlyStats;
