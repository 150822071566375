
import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; 

dayjs.extend(utc);

export default function DateRangePickerComponent({ onDateChange, defaultValue }) {
  const today = dayjs();
  const [value, setValue] = React.useState(
    defaultValue || [today.subtract(1, 'day').utc(), today.utc()]
  );

  const handleDateChange = (newValue) => {
    const [start, end] = newValue;
    const utcStart = start ? start.utc() : null;
    const utcEnd = end ? end.utc() : null;

    setValue([utcStart, utcEnd]);
    if (onDateChange) {
      onDateChange([utcStart, utcEnd]);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        value={value}
        onChange={handleDateChange}
        startText="Start Date"
        endText="End Date"
      />
    </LocalizationProvider>
  );
}
