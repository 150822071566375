import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { BASE_URL } from "../Services";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { TextField, IconButton, Button, Box, Card, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import Header from "../UserSide/Components/HeaderComponent";
import Footer from "../UserSide/Components/FooterCompnent";

const ResetPassword = () => {
  const location = useLocation();
  const { user } = location.state || {};
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phone || "",
    password: "",
    password_confirmation: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name,
        email: user.email,
        phone: user.phone,
        password: "",
        password_confirmation: "",
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (formData.password !== formData.password_confirmation) {
      toast.error("Passwords do not match.");
      setLoading(false);
      return;
    } else if (formData.password.length < 8) {
      toast.error("Password must be at least 8 characters long.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.put(
        `${BASE_URL}/update-user-details/${user.id}`,
        formData
      );

      if (response.data.success) {
        navigate("/");
        toast.success("User details have been successfully updated.");
      } else {
        toast.error("Failed to update user details.");
      }
    } catch (error) {
      console.error("Error:", error.response ? error.response.data : error.message);
      toast.error("Failed to update user details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <Header />
      <div style={{height:"20vh"}}/>
      <Box >
        <Card
          sx={{
            maxWidth: 500,
            mx: "auto",
            p: 4,
            boxShadow: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h5"
            component="h3"
            sx={{ textAlign: "center", color: "#35B5FF", mb: 3 }}
          >
            Reset Your Password
          </Typography>
          <form onSubmit={handleSubmit}>
            {/* Password Field */}
            <Box sx={{ mb: 3, position: "relative" }}>
              <TextField
                label="New Password"
                name="password"
                type={showPassword ? "text" : "password"}
                fullWidth
                value={formData.password}
                onChange={handleChange}
                required
              />
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                sx={{ position: "absolute", right: 8, top: "50%", transform: "translateY(-50%)" }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Box>

            {/* Confirm Password Field */}
            <Box sx={{ mb: 3, position: "relative" }}>
              <TextField
                label="Confirm New Password"
                name="password_confirmation"
                type={showConfirmPassword ? "text" : "password"}
                fullWidth
                value={formData.password_confirmation}
                onChange={handleChange}
                required
              />
              <IconButton
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                sx={{ position: "absolute", right: 8, top: "50%", transform: "translateY(-50%)" }}
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Box>

            {/* Submit Button */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ borderRadius: 2, py: 1.5 }}
              disabled={loading}
            >
              {loading ? "Updating..." : "Update Details"}
            </Button>
          </form>
        </Card>
      </Box>
      <div style={{height:"5vh"}}/>
      <Footer profile={false} />
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;
