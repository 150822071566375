import React from 'react'
import { Helmet } from 'react-helmet';

import Header from '../../Components/HeaderComponent';
import ProductsListComponent from '../HomeScreen/Components/ProductsListComponent';
import Footer from '../../Components/FooterCompnent';
 
const Prizes = () => {
  return (
    <div className="App">
      <Helmet>
        <title>Win Exciting Prizes with Enaam - Fun & Skill-based Games</title>
          <meta name="description" content="Participate in Enaam's skill-based competitions and win amazing prizes like gadgets, home appliances, and cars. Join now for daily opportunities!" />
          <link rel="canonical" href="https://enaam.pk/prizes" />
      </Helmet>
        <Header/>
        <div style={{height:"15vh "}} className='mt-5'/>
        <div className='p-2'>
        <ProductsListComponent/>
        </div>
        <Footer/>
    </div>
  )
}

export default Prizes;