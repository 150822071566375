import React, { useEffect , useState } from "react";
import { BASE_URL } from "../../../Services";
import { useUser } from "../../../Services/UserContext";
import { Helmet } from "react-helmet";

import HomeBanner from "./Components/HomeBannerComponent";
import RecentLuckyDraws from "./Components/VideosSectionComponent";
import ProductsListComponent from "./Components/ProductsListComponent";
import InsightSection from "./Components/InsightSectionComponent";
import Footer from "../../Components/FooterCompnent";
import HangWinnerScreen from "./Components/HangWinnerScreen";
import ModalComponent from "./Components/OfferModal";

import '../../css/App.css';

function App() {
  const {getUserByID} = useUser()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [offerData, setOfferData] = useState(null);
  useEffect(() => {
    const fetchActiveOffer = async () => {
      try {
        const response = await fetch(`${BASE_URL}/offers/active`);
        if (!response.ok) {
          throw new Error('Failed to fetch offers');
        }
        const Data = await response.json();
        if (Data && Object.keys(Data).length > 0 ) {
          setOfferData(Data);
          setIsModalVisible(true);
        } else {
          setOfferData(null); 
          setIsModalVisible(false); 
        }
      } catch (error) {
        console.error("Error fetching active offer:", error);
        setOfferData(null);
        setIsModalVisible(false);
      }
    };
  
    fetchActiveOffer();
  }, []);
  
  
  const handleClose = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
   
    getUserByID()
  },[])
  return (
    <div className="App">
      <Helmet>
        <title>Enaam.pk - Win Prizes with Fun Skill-Based Challenges</title>
          <meta name="description" content="Join Enaam.pk for exciting competitions where skills win you prizes like gadgets, appliances, and cars. Participate daily and become a winner today!" />
          <link rel="canonical" href="https://enaam.pk/" />
      </Helmet>
      <HomeBanner />
      {/* <PromoCodeModal /> */}
     
      <ModalComponent 
        isVisible={isModalVisible} 
        onClose={handleClose} 
        offerData={offerData}
      /> 

      <RecentLuckyDraws />
      <InsightSection />
      <ProductsListComponent />
      <HangWinnerScreen />
      <Footer />
    </div>
  );
}

export default App;
