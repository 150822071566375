import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast, ToastContainer} from 'react-toastify';
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';
import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';
import { Pagination } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import whatsappLogo from "../../UserSide/images/whatsapp_logo.png";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ParticipantsList = () => {
    const [participants, setParticipants] = useState([]);
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [rewardFilterText, setRewardFilterText] = useState('');
    const [selectedReward, setSelectedReward] = useState('');
    const [amountFilter, setAmountFilter] = useState('');
    const [answerFilterText, setAnswerFilterText] = useState('');
    const [usernameFilterText, setUsernameFilterText] = useState('');
    const { userData } = useUser();
    const [sortOption, setSortOption] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    const [uniqueRewards, setUniqueRewards] = useState([]);
    const [page, setPage] = useState(1); 
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

  const [currentProcessingId, setCurrentProcessingId] = useState(null);
    const itemsPerPage = 10;

    useEffect(() => {
        const fetchParticipants = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/participants`, {
                    headers: { Authorization: `Bearer ${userData?.token}` },
                });
                const sortedParticipants = response.data.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                );
                setParticipants(sortedParticipants);
                setFilteredParticipants(sortedParticipants);
                extractUniqueRewards(sortedParticipants);
                const total = sortedParticipants.reduce(
                    (sum, participant) => sum + (parseFloat(participant.total_amount) || 0),
                    0
                );
                setTotalAmount(total);
            } catch (error) {
                console.error('Error fetching participants:', error);
                toast.error(`Error fetching participants: ${error.response?.data?.message || error.message}`);
            } finally {
                setLoading(false);
            }
        };
        fetchParticipants();
    }, []);

    const extractUniqueRewards = (participants) => {
        const rewardsSet = new Set();
        participants.forEach((participant) => {
            const products = parseProducts(participant.processed_invoice?.products);
            products.forEach((product) => {
                if (product.reward && product.reward.name) {
                    rewardsSet.add(product.reward.name);
                }
            });
        });
        setUniqueRewards(Array.from(rewardsSet));
    };
    const convertTimeToSeconds = (timeString) => {
    if (!timeString) return 0; 
    const [minutes, seconds] = timeString.split(':').map(Number);
    return (minutes * 60) + seconds;
};
const handleResendMessage = async (userId, message, ticketIds) => {
    if (!userId || userId === "N/A") {
      toast.error("Invalid User ID.");
      return;
    }
    setCurrentProcessingId(userId);
    try {
      const response = await axios.post(`${BASE_URL}/resend-message`, {
        user_id: userId,
        message: message,
        ticket_id: ticketIds,
      });
      toast.success("Message sent successfully.");
    } catch (error) {
      const errorMsg =
        error.response?.data?.message || "Failed to send message. Please try again.";
      toast.error(errorMsg);
    } finally {
      setCurrentProcessingId(null);
    }
  };
  

useEffect(() => {
    const lowercasedRewardFilterText = rewardFilterText.toLowerCase();
    const lowercasedAnswerFilterText = answerFilterText.toLowerCase();
    const lowercasedUsernameFilterText = usernameFilterText.toLowerCase();


    const filtered = participants.filter((participant) => {
        const username = participant.processed_invoice?.user?.name?.toLowerCase() || '';
        const userNumber = participant.userNumber?.toString() || '';
        const invoiceId = participant.processed_invoice?.invoice_id?.toString()?.toLowerCase() || '';

        const hasRewardMatch =
            participant.processed_invoice &&
            participant.processed_invoice.products &&
            parseProducts(participant.processed_invoice.products).some((product) =>
                product.reward?.name.toLowerCase().includes(lowercasedRewardFilterText)
            );

        const hasSelectedRewardMatch =
            selectedReward === '' ||
            (participant.processed_invoice &&
                parseProducts(participant.processed_invoice.products).some(
                    (product) => product.reward?.name === selectedReward
                ));

        const hasAnswerMatch =
            participant.processed_invoice?.is_correct?.toLowerCase().includes(lowercasedAnswerFilterText) || false;

        const hasUsernameMatch =
            lowercasedUsernameFilterText === '' || username.includes(lowercasedUsernameFilterText) ||
            userNumber.includes(lowercasedUsernameFilterText) ||
            invoiceId.includes(lowercasedUsernameFilterText);


        const amountMatch =
            amountFilter === '' ||
            (amountFilter === 'greater' && participant.total_amount > 0) ||
            (amountFilter === 'less' && participant.total_amount == 0);

        const participantDate = new Date(participant.created_at); 
        const startDateUTC = startDate ? new Date(`${startDate}T00:00:00Z`) : null;
        const endDateUTC = endDate ? new Date(`${endDate}T23:59:59Z`) : null;

        const dateMatch =
            (!startDateUTC || participantDate >= startDateUTC) &&
            (!endDateUTC || participantDate <= endDateUTC);

        return (
            (lowercasedRewardFilterText === '' || hasRewardMatch) &&
            hasSelectedRewardMatch &&
            (lowercasedAnswerFilterText === '' || hasAnswerMatch) &&
            hasUsernameMatch &&
            amountMatch &&
            dateMatch 
        );
    });

    const sortedFilteredParticipants = filtered.sort((a, b) => {
        const answerTimeA = a.processed_invoice ? convertTimeToSeconds(a.processed_invoice.answer_time) : 0;
        const answerTimeB = b.processed_invoice ? convertTimeToSeconds(b.processed_invoice.answer_time) : 0;
        if (sortOption === "") {
            return 0;
        }
    
        return sortOption === 'asc' ? answerTimeA - answerTimeB : answerTimeB - answerTimeA;
    });
    setPage(1);
    setFilteredParticipants(sortedFilteredParticipants);
}, [rewardFilterText,selectedReward,answerFilterText,usernameFilterText,participants,sortOption,amountFilter,startDate,endDate]);

    const downloadPDF = () => { 
        const pdf = new jsPDF();
        const pageHeight = pdf.internal.pageSize.height;
        pdf.setFontSize(20);
        pdf.text("Participant Tickets List", 10, 20);
        pdf.setFontSize(12);
        let position = 30;
        const allRewards = new Set();
        const paidParticipants = (filteredParticipants || []).filter(
            (participant) => participant.total_amount > 0
        );
        const unpaidParticipants = (filteredParticipants || []).filter(
            (participant) => participant.total_amount <= 0
        );
        const addParticipantDetails = (participant, currentPosition) => {
            const processedInvoice = participant.processed_invoice || {};
            const userName = String(processedInvoice.user?.name || "N/A");
            const invoiceId = String(processedInvoice.invoice_id || "N/A");
            const answer = String(processedInvoice.is_correct ?? "N/A");
            const email = String(processedInvoice.user?.email || "N/A");
            const userNumber = String(participant.userNumber || "N/A");
            const answerTime = String(processedInvoice.answer_time || "N/A");
            const tickets = processedInvoice.ticket_id || ["N/A"];
    
            const rewards = processedInvoice.products
                ? parseProducts(processedInvoice.products).map(
                      (product) => product.reward?.name || "N/A"
                  )
                : ["N/A"];
    
            rewards.forEach((reward) => allRewards.add(reward));
    
            const ticketRewards = {};
            let lastReward = "No Reward";
    
            tickets.forEach((ticketID, index) => {
                if (index < rewards.length) {
                    lastReward = rewards[index];
                    ticketRewards[ticketID] = lastReward;
                } else {
                    ticketRewards[ticketID] = lastReward;
                }
            });
    
            tickets.forEach((ticketID) => {
                pdf.setFont("helvetica", "bold");
                pdf.text(`Ticket ID: ${String(ticketID)}`, 10, currentPosition + 10);
                pdf.setFont("helvetica", "normal");
    
                pdf.text("Date:", 130, currentPosition + 10);
                pdf.text(
                    new Date(participant.created_at || Date.now()).toLocaleDateString(),
                    160,
                    currentPosition + 10
                );
    
                pdf.text("User Name:", 10, currentPosition + 20);
                pdf.text(userName, 50, currentPosition + 20);
    
                pdf.text("Invoice ID:", 130, currentPosition + 20);
                pdf.text(invoiceId, 160, currentPosition + 20);
    
                pdf.text("Number:", 10, currentPosition + 30);
                pdf.text(userNumber, 50, currentPosition + 30);
    
                pdf.text("Answer:", 130, currentPosition + 30);
                pdf.text(answer, 160, currentPosition + 30);
    
                pdf.text("Email:", 10, currentPosition + 40);
                pdf.text(email, 50, currentPosition + 40);
    
                pdf.text("Answer Time:", 130, currentPosition + 40);
                pdf.text(answerTime, 160, currentPosition + 40);
    
                const rewardForTicket = ticketRewards[ticketID] || "No Reward";
                pdf.text("Reward:", 10, currentPosition + 50);
                pdf.text(String(rewardForTicket), 50, currentPosition + 50);
    
                pdf.text("Amount:", 130, currentPosition + 50);
                pdf.text(
                    Number(participant.total_amount || 0).toFixed(2),
                    160,
                    currentPosition + 50
                );
    
                const dividerY = currentPosition + 60;
                pdf.line(10, dividerY, 200, dividerY);
    
                currentPosition += 70;
    
                if (currentPosition > pageHeight - 20) {
                    pdf.addPage();
                    currentPosition = 15;
                }
            });
    
            return currentPosition;
        };
        paidParticipants.forEach((participant) => {
            position = addParticipantDetails(participant, position);
        });
        unpaidParticipants.forEach((participant) => {
            position = addParticipantDetails(participant, position);
        });
        const rewardNames = Array.from(allRewards)
            .join("-")
            .replace(/:/g, "")
            .replace(/\s+/g, "_");
        const filename = `Tickets-list(${rewardNames}).pdf`;
        pdf.save(filename);
    };
    
const downloadExcel = () => {
    const allRewards = new Set();
    const paidParticipants = filteredParticipants.filter(participant => participant.total_amount > 0);
    const unpaidParticipants = filteredParticipants.filter(participant => participant.total_amount <= 0);
    const processParticipantDetails = (participant) => {
        const processedInvoice = participant.processed_invoice || {};
        const userName = processedInvoice.user ? processedInvoice.user.name : 'N/A';
        const invoiceId = processedInvoice.invoice_id || 'N/A';
        const answer = processedInvoice.is_correct || 'N/A';
        const email = processedInvoice.user ? processedInvoice.user.email : 'N/A';
        const userNumber = participant.userNumber;
        const answerTime = processedInvoice.answer_time || 'N/A';
        const tickets = processedInvoice.ticket_id || ['N/A'];
        const rewards = processedInvoice.products
            ? parseProducts(processedInvoice.products).map(product => product.reward ? product.reward.name : 'N/A')
            : ['N/A'];

        rewards.forEach(reward => allRewards.add(reward));
        const ticketRewards = {};
        let lastReward = 'No Reward';
        tickets.forEach((ticketID, index) => {
            if (index < rewards.length) {
                lastReward = rewards[index];
                ticketRewards[ticketID] = lastReward;
            } else {
                ticketRewards[ticketID] = lastReward;
            }
        });
        return tickets.map(ticketID => ({
            "SR No.": null, 
            "Ticket ID": ticketID,
            "User Name": userName,
            "Invoice ID": invoiceId,
            "Number": userNumber,
            "Answer": answer,
            "Email": email,
            "Answer Time": answerTime,
            "Reward": ticketRewards[ticketID] || 'No Reward',
            "Amount": Number(participant.total_amount).toFixed(2),
            "Date": new Date(participant.created_at).toLocaleDateString(),
        }));
    };

    const data = [];
    paidParticipants.forEach(participant => {
        data.push(...processParticipantDetails(participant));
    });
    unpaidParticipants.forEach(participant => {
        data.push(...processParticipantDetails(participant));
    });

    data.forEach((row, index) => {
        row["SR No."] = index + 1;
    });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const columnWidths = [
        { wch: 10 }, 
        { wch: 15 }, 
        { wch: 25 }, 
        { wch: 12 }, 
        { wch: 15 }, 
        { wch: 10 }, 
        { wch: 30 }, 
        { wch: 10 }, 
        { wch: 15 },
        { wch: 18 },
        { wch: 15 },
    ];
    worksheet["!cols"] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Participants");
    const rewardNames = Array.from(allRewards).join('-').replace(/:/g, '').replace(/\s+/g, '_');
    const filename = `Tickets-list(${rewardNames}).xlsx`;
    XLSX.writeFile(workbook, filename);
};
    const parseProducts = (products) => {
        if (typeof products === 'string') {
            try {
                const parsed = JSON.parse(products);
                return Array.isArray(parsed) ? parsed : [];
            } catch (error) {
                console.error('Error parsing products JSON:', error);
                return [];
            }
        }
        return Array.isArray(products) ? products : [];
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const displayedParticipants = filteredParticipants.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );
  
    if (loading)
        return (
            <div className="container-fluid">
                <div className="row">
                    <Sidebar onToggleSidebar={handleSidebarToggle} />
                    <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                        <div className="dashboard-content">
                            <div className="d-flex justify-content-center" style={{ height: '70vh' }}>
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="dashboard-content p-4">
                    <div className="total-users-box mb-4 p-3 bg-light rounded shadow-sm d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                         <div className="d-flex align-items-center mb-3 mb-md-0">
                             <h5 className="mb-0">Participants: {filteredParticipants.length}</h5>
                            
                         </div>
                         <div className="d-flex gap-2 flex-wrap justify-content-center justify-content-md-end">
                             <h5 className="text-dark shadow-sm p-1 px-2 bg-body rounded border">Sales: <span className='text-success fw-bold'>Rs. {totalAmount.toFixed(2)}</span></h5>
                             <button className="btn btn-primary" onClick={downloadPDF}><bi className="bi bi-download"></bi> PDF</button>
                             <button className="btn btn-success" onClick={downloadExcel}><bi className="bi bi-download"></bi> Excel</button>
                         </div>
                     </div>

                         <div className='d-flex justify-content-center mb-3'>
                           
                             <input
                                type="text"
                                placeholder="Filter by User"
                                className="form-control me-2"
                                value={usernameFilterText}
                                onChange={(e) => setUsernameFilterText(e.target.value)}
                            />

                            <select
                                className="form-control"
                                value={selectedReward}
                                onChange={(e) => setSelectedReward(e.target.value)} 
                            >
                                <option value="">Select Reward</option>
                                {uniqueRewards.map((reward, index) => (
                                    <option key={index} value={reward}>{reward}</option>
                                ))}
                            </select>
                            <select
                                className="form-control ms-2"
                                value={amountFilter}
                                onChange={(e) => setAmountFilter(e.target.value)}
                            >
                                <option value="">All Amounts</option>
                                <option value="greater">Paid</option>
                                <option value="less">Unpaid</option>
                            </select>
                            <select
                                className="form-control ms-2"
                                value={answerFilterText}
                                onChange={(e) => setAnswerFilterText(e.target.value)}
                            >
                                <option value="">All</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            <select 
                                        className="form-control ms-2"
                                        value={sortOption} 
                                        onChange={(e) => setSortOption(e.target.value)} >
                                        <option value="">Time</option>    
                                        <option value="asc">MAX Time</option>
                                        <option value="desc">MIN Time</option>
                                </select>
                                <input
                                    type="date"
                                    className="form-control ms-2"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    />
                                    <input
                                    type="date"
                                    className="form-control ms-2"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    />
                        </div>
                        
                        <div className="table-responsive">
                            {displayedParticipants.length > 0 ? (
                                <>
                                    <table className="table table-hover table-striped table-bordered shadow-sm rounded" style={{ fontSize: '14px' }}>
                                        <thead className="table-dark">
                                            <tr>
                                                <th>Sr</th>
                                                <th className="col-2">Username</th>
                                                <th>Invoice ID</th>
                                                <th>Amount</th>
                                                <th>Number</th>
                                                <th>Products</th>
                                                <th>Ticket IDs</th>
                                                <th>Answer</th>
                                                <th className="col-1 text-center">Answer Time</th>
                                                <th className="col-1 text-center">Date</th>
                                                <th className="col-1 text-center">Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayedParticipants.map((participant, index) => (
                                                <tr key={participant.id}>
                                                    <td className="align-middle">{(page - 1) * itemsPerPage + index + 1}</td>
                                                    <td className="align-middle">{participant.processed_invoice && participant.processed_invoice.user ? participant.processed_invoice.user.name : 'N/A'}</td>
                                                 <td className="align-middle">{participant.processed_invoice ? participant.processed_invoice.invoice_id : 'N/A'}</td>
                                          
                                                 <td className="align-middle">{Number(participant.total_amount).toFixed(2)}</td>
                                                 <td className="align-middle">{participant.userNumber}</td>
                                                 <td className="align-middle">
                                                     {participant.processed_invoice && participant.processed_invoice.products ? (
                                                        parseProducts(participant.processed_invoice.products).map((product, index) => (
                                                            <div className='d-flex justify-content-between' key={index}>
                                                                {product && (
                                                                    <div>
                                                                        <p className='text-dark'>{product.name}</p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>No products or invalid products data</p>
                                                    )}
                                                </td>
                                                <td className="align-middle">
                                                    {Array.isArray(participant.processed_invoice?.ticket_id) 
                                                        ? participant.processed_invoice.ticket_id.join(', ') 
                                                        : participant.processed_invoice?.ticket_id || 'N/A'}
                                                </td>

                                                <td className="align-middle text-center">{participant.processed_invoice ? participant.processed_invoice.is_correct : 'N/A'}</td>
                                                <td className="align-middle text-center">{participant.processed_invoice ? participant.processed_invoice.answer_time : 'N/A'}</td>
                                                <td className="align-middle text-center">{new Date(participant.created_at).toLocaleDateString() || 'N/A'}</td>
                                                <td className="align-middle text-center">
                                                    <div className="d-flex gap-2 g-0 justify-content-center">
                                                    <Link to={`/dashboard/participants/${participant.id}`} className="btn btn-info btn-sm">
                                                    
                                                    <i className="bi bi-eye"></i></Link>
                                                    
                                                    <button
                                                        className="btn btn-primary btn-sm"
                                                        onClick={() => {
                                                            const ticketIds = Array.isArray(participant.processed_invoice?.ticket_id)
                                                              ? participant.processed_invoice.ticket_id.join(', ')
                                                              : participant.processed_invoice?.ticket_id || 'N/A';
                                                            
                                                            const message = `Hi, your ticket number(s) for the Enaam.pk Lucky Draw is/are [ ${ticketIds} ]. Keep it safe! Good luck! Stay in touch with us for more updates.`;
                                                            
                                                            handleResendMessage(participant.processed_invoice?.user?.id || "N/A", message);
                                                          }}
                                                        >
                                                         {currentProcessingId === participant.processed_invoice?.user?.id  ? (
                                                            <i className="bi bi-hourglass-split"></i>
                                                            ) : (
                                                            <i className="bi bi-envelope"></i>
                                                            )}
                                                        </button>
                                                        <img 
                                                            src={whatsappLogo}
                                                            style={{ cursor: 'pointer', width: 32, height: 32, justifyContent: "center", alignSelf: "flex-end" }}
                                                            className=''
                                                            // onClick={toggleDropdown}
                                                            alt=""
                                                            onClick={() => {
                                                                let phoneNumber = participant.userNumber;
                                                                if (phoneNumber.startsWith('0')) {
                                                                    phoneNumber = '92' + phoneNumber.slice(1);
                                                                }
                                                                const message = encodeURIComponent(
                                                                    "Thank you for joining Enaam.pk! Ready to turn your dreams into reality? Join Enaam.pk now for a chance to win amazing rewards!"
                                                                );
                                                                const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
                                                                window.open(whatsappURL, '_blank');
                                                            }}/>
                                                    </div>
                                                </td>
                                                  
                                                    {/* {dropdownVisible && (
                                                            <div
                                                                style={{
                                                                    position: 'relative',
                                                                    top: '100%',
                                                                    left: '50%',
                                                                    transform: 'translateX(-50%)',
                                                                    backgroundColor: '#fff',
                                                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                                                    borderRadius: '4px',
                                                                    zIndex: 1,
                                                                    padding: '10px',
                                                                    minWidth: '150px'
                                                                }}
                                                            >
                                                                <button
                                                                    style={{
                                                                        width: '100%',
                                                                        padding: '8px 12px',
                                                                        border: 'none',
                                                                        backgroundColor: '#f1f1f1',
                                                                        borderRadius: '4px',
                                                                        cursor: 'pointer',
                                                                        textAlign: 'left',
                                                                        marginBottom: '5px'
                                                                    }}
                                                                    onClick={() => alert('Message 1')}
                                                                >
                                                                    Message 1
                                                                </button>
                                                                <button
                                                                    style={{
                                                                        width: '100%',
                                                                        padding: '8px 12px',
                                                                        border: 'none',
                                                                        backgroundColor: '#f1f1f1',
                                                                        borderRadius: '4px',
                                                                        cursor: 'pointer',
                                                                        textAlign: 'left'
                                                                    }}
                                                                    onClick={() => alert('Message 2')}
                                                                >
                                                                    Message 2
                                                                </button>
                                                            </div>
                                                        )} */}
                                                  
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-center">
                                        <Pagination
                                            count={Math.ceil(filteredParticipants.length / itemsPerPage)}
                                            page={page}
                                            onChange={handlePageChange}
                                            color="primary"
                                            variant='outlined'
                                        />
                                    </div>
                                </>
                            ) : (
                                <p className="text-center fw-bold text-danger">No participants found.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer /> 
        </div>
    );
};

export default ParticipantsList;
