import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import { useUser } from '../../Services/UserContext';
import { BASE_URL } from '../../Services';

import Sidebar from '../sidebar/Sidebar'; 
import Loader from '../../UserSide/Components/LoaderComponent';
import 'react-toastify/dist/ReactToastify.css';
import './Styles.css'; 

const ContactList = () => {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const {userData} = useUser();

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/contacts`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                    },
                });
                setContacts(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching contacts:', error);
                toast.error(`Error fetching contacts: ${error.response?.data?.message || error.message}`);
                setLoading(false);
            }
        };

        fetchContacts();
    }, []);

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                   
                    <div className="dashboard-content ">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-body rounded">Contact List</h1>
                    </div>
                        {loading ? (
                            <div className="d-flex justify-content-center" style={{ height: '60vh' }}>
                                <Loader />
                            </div>
                        ) : (
                            <div className="table-responsive mt-2">
                                <table className="table table-striped table-bordered shadow-sm rounded" style={{fontSize: '14px'}}>
                                    <thead className="table-dark">
                                        <tr>
                                            <th>Sr.</th>
                                            <th>Username</th>
                                            <th>Email</th>
                                            <th>Message</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contacts.length === 0 ? (
                                            <tr>
                                                <td colSpan="4" className="text-center">No contacts found</td>
                                            </tr>
                                        ) : (
                                            contacts.map((contact, index) => (
                                                <tr key={index}>
                                                  <td className='align-middle'>{index + 1}</td>
                                                    <td className='align-middle'>{contact.username}</td>
                                                    <td className='align-middle'>{contact.email}</td>
                                                    <td className='align-middle'>{contact.message}</td>
                                                    <td className='align-middle'>{new Date(contact.created_at).toLocaleDateString()}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ToastContainer /> 
        </div>
    );
};

export default ContactList;
