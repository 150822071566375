
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../Services';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';
import 'react-toastify/dist/ReactToastify.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const HangWinnersList = () => {
    const [winners, setWinners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [winnerToDelete, setWinnerToDelete] = useState(null);

    useEffect(() => {
        const fetchWinners = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/hang-tower-winners`);
                setWinners(response.data.winners);
            } catch (error) {
                console.error('Error fetching winners:', error);
                toast.error(`Error fetching winners: ${error.response?.data?.message || error.message}`);
            } finally {
                setLoading(false);
            }
        };
        fetchWinners();
    }, []);

    const handleDelete = async () => {
        try {
            await axios.delete(`${BASE_URL}/hang-tower-winners/${winnerToDelete}`);
            setWinners(winners.filter(winner => winner.id !== winnerToDelete));
            setShowDeleteModal(false);
        } catch (error) {
            console.error('Error deleting winner:', error);
            toast.error(`Error deleting winner: ${error.response?.data?.message || error.message}`);
        }
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        doc.text('Hang Tower Winners List', 14, 16);
        doc.autoTable({
            startY: 22,
            head: [['Name', 'Reward', 'Draw Date', 'Location', 'Image']],
            body: winners.map(winner => [
                winner.name,
                winner.reward,
                winner.draw_date,
                winner.location,
                winner.image ? winner.image : 'No Image'
            ]),
            margin: { top: 22 },
        });
        doc.save('hang-tower-winners-list.pdf');
    };

    const downloadCSV = () => {
        const csvRows = [];
        csvRows.push(['Name', 'Reward', 'Draw Date', 'Location', 'Image'].join(','));
        winners.forEach(winner => {
            const row = [
                winner.name,
                winner.reward,
                winner.draw_date,
                winner.location,
                winner.image ? winner.image : 'No Image'
            ].join(',');
            csvRows.push(row);
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'hang-tower-winners-list.csv';
        link.click();
    };

    if (loading) return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="dashboard-content">
                        <div className="d-flex justify-content-center" style={{ height: '60vh' }}>
                            <Loader />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="dashboard-content">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-body rounded">Hang Tower Winners List</h1>
                            {/* <div className="d-flex ms-2">
                                <button className="btn btn-primary me-2" onClick={downloadPDF}>Download PDF</button>
                                <button className="btn btn-primary" onClick={downloadCSV}>Download CSV</button>
                            </div> */}
                        </div>
                        <Link to="/hang-tower-winners/create" className="btn btn-primary mb-3">Add New Winner</Link>
                        <div className="table-responsive">
                            {winners.length > 0 ? (
                                <table className="table table-hover table-striped table-bordered shadow-sm rounded" style={{ fontSize: '14px' }}>
                                    <thead className="table-dark">
                                        <tr>
                                            <th>Name</th>
                                            <th>Reward</th>
                                            <th>Date</th>
                                            <th>Location</th>
                                            <th className="text-center">Image</th>
                                            <th className="col-1 text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {winners.map(winner => (
                                            <tr key={winner.id}>
                                                <td className="align-middle">{winner.name}</td>
                                                <td className="align-middle">{winner.reward}</td>
                                                <td className="align-middle">{winner.draw_date}</td>
                                                <td className="align-middle">{winner.location}</td>
                                                <td className="text-center align-middle p-1">
                                                    {winner.image ? (
                                                        <img src={winner.image} alt={winner.name} style={{ width: '90%', height: '80px', borderRadius: '10px' }} />
                                                    ) : 'No Image'}
                                                </td>
                                                <td className="text-center align-middle">
                                                    <div className="d-flex gap-2 justify-content-center">
                                                        <Link to={`/hang-tower-winners/${winner.id}/edit`} className="btn btn-primary btn-sm">
                                                            <i className="bi bi-pencil"></i>
                                                        </Link>
                                                        <button onClick={() => {
                                                            setWinnerToDelete(winner.id);
                                                            setShowDeleteModal(true);
                                                        }} className="btn btn-danger btn-sm">
                                                            <i className="bi bi-trash"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="text-center">
                                    <p className="fs-5 text-danger">No winners found</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showDeleteModal && (
                <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deletion</h5>
                                <button type="button" className="btn-close" onClick={() => setShowDeleteModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this winner?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => setShowDeleteModal(false)}>Cancel</button>
                                <button type="button" className="btn btn-danger" onClick={handleDelete}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>
    );
};

export default HangWinnersList;
