import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../Services";
import { useUser } from "../../Services/UserContext";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";

function EditOffer() {
  const navigate = useNavigate();
  const { id } = useParams(); 
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    status: false,
  });
  const [image, setImage] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const { userData } = useUser();

  useEffect(() => {
    const fetchOffer = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/offers/${id}`, {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });
        setFormData({
          title: response.data.title,
          description: response.data.description,
          status: response.data.status ? "1" : "0",
        });
      } catch (error) {
        console.error("Error fetching offer:", error);
        toast.error("Failed to fetch offer details.");
      } finally {
        setLoading(false);
      }
    };

    fetchOffer();
  }, [id, userData?.token]);

  const handleChange = (e) => {
    const { name, value, files, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
    if (type === "file") setImage(files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.put(`${BASE_URL}/offers/${id}`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      if (image) {
        const imageData = new FormData();
        imageData.append("image", image);
        await axios.post(
          `${BASE_URL}/offers/${id}/upload-image`,
          imageData,
          {
            headers: {
              Authorization: `Bearer ${userData?.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      toast.success("Offer updated successfully!");
      navigate("/dashboard/offers");
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      toast.error(
        `Failed to update offer: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div className="container-fluid mt-4">
            <div className="p-4 bg-light rounded shadow-sm">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              ) : (
                <div>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h1 className="mb-4 fs-4 fw-bold text-dark">Edit Offer</h1>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Offer Image</label>
                      <input
                        type="file"
                        className="form-control"
                        name="image_url"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Active Status</label>
                      <select
                        className="form-select ms-2"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                      >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>

                    <button type="submit" className="btn btn-primary shadow-sm">
                      Update Offer
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default EditOffer;
