import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../Services';

import Header from '../UserSide/Components/HeaderComponent';
import Footer from '../UserSide/Components/FooterCompnent';

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    TextField,
    Typography,
    Grid,
} from '@mui/material';

function ForgotPassword() {
    const [input, setInput] = useState('');
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [step, setStep] = useState('request');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleRequest = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/forgot-password`, { input });
            setMessage(response.data.message);
            setError('');
            setStep('verify');
        } catch (error) {
            setError('Failed to send OTP.');
            setMessage('');
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        try {
            const otpValue = otp.join('');
            const response = await axios.post(`${BASE_URL}/verify-otp-reset`, { input, otp: otpValue });

            if (response.data.status === 200) {
                setMessage(response.data.message);
                setError('');

                const userResponse = await axios.post(`${BASE_URL}/get-user-details`, { input });

                navigate('/reset-password', { state: { user: userResponse.data.user } });
            } else {
                setError('Invalid OTP.');
                setMessage('');
            }
        } catch (error) {
            setError('Invalid OTP.');
            setMessage('');
        }
    };

    const handleOtpChange = (value, index) => {
        const updatedOtp = [...otp];
        updatedOtp[index] = value.slice(-1); // Allow only one character per field
        setOtp(updatedOtp);

        // Automatically move to the next field
        if (value && index < otp.length - 1) {
            document.getElementById(`otp-${index + 1}`).focus();
        }
    };

    return (
        <div>
            <Header />
            <Box
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh', mt: 20 , mb: 4 }}
            >
                <Card className="glow" sx={{ width: 600, boxShadow: 3, p: 3 }}>
                    <Box textAlign="center" mb={1}>
                        <img
                            src={require('../UserSide/images/logo.png')}
                            alt="Login Icon"
                            style={{ width: '140px', height: '65px' }}
                        />
                    </Box>
                    <CardHeader
                        title={step === 'request' ? 'Forgot Password' : 'Verify OTP'}
                        titleTypographyProps={{ variant: 'h5', textAlign: 'center', color: '#35B5FF' }}
                    />
                    <CardContent>
                        {step === 'request' ? (
                            <form onSubmit={handleRequest}>
                                <TextField
                                    fullWidth
                                    label="Email or Phone Number"
                                    variant="outlined"
                                    value={input}
                                    onChange={(e) => setInput(e.target.value)}
                                    required
                                    sx={{
                                        mb: 3,
                                    }}
                                />
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        bgcolor: '#35B5FF',
                                        fontSize: '16px',
                                        py: 1,
                                        '&:hover': {
                                            bgcolor: '#1e90ff',
                                        },
                                    }}
                                >
                                    Send OTP
                                </Button>
                            </form>
                        ) : (
                            <form onSubmit={handleVerifyOtp}>
                                <Grid container spacing={5} justifyContent="center" mb={3}>
                                    {otp.map((digit, index) => (
                                        <Grid item key={index}>
                                            <TextField
                                                id={`otp-${index}`}
                                                value={digit}
                                                onChange={(e) => handleOtpChange(e.target.value, index)}
                                                variant="outlined"
                                                inputProps={{
                                                    maxLength: 1,
                                                    style: { textAlign: 'center' },
                                                }}
                                                sx={{ width: 50 }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        bgcolor: '#35B5FF',
                                        fontSize: '16px',
                                        py: 1,
                                        '&:hover': {
                                            bgcolor: '#1e90ff',
                                        },
                                    }}
                                >
                                    Verify OTP
                                </Button>
                            </form>
                        )}
                        {message && (
                            <Typography
                                variant="body2"
                                color="success.main"
                                textAlign="center"
                                sx={{ mt: 2 }}
                            >
                                {message}
                            </Typography>
                        )}
                        {error && (
                            <Typography
                                variant="body2"
                                color="error.main"
                                textAlign="center"
                                sx={{ mt: 2 }}
                            >
                                {error}
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </Box>
            <Footer />
        </div>
    );
}

export default ForgotPassword;
